export const firstPageZH = {
  aboutWallTitle: '关于长城',
  aboutWallTitleDes1: `香港长城投资商学院成立以来，始终秉承“教育服务社会发展”的办学理念，用“国际教育”传递思维价值；秉承“资本服务产业发展”的投资理念，以“长城基金”助力企业成长；秉承“国际化思维服务企业国际化发展”的产业服务理念，以“国际服务”带动产业国际化发展。`,
  aboutWallTitleDes2: `荏苒十载兴未减，在深圳这片朝气蓬勃的土地上，香港长城投资商学院在人工智能、互联网、新能源、新材料、航空航天、生物制药、大健康等战略新兴产业，先后累计举办超过上千场融资培训、项目路演会、高端峰会、圆桌论坛等活动。`,
  GreatWallFundTxt1: `长城基金拥有多元化投资人阵容，由政府、全球风险投资头部金融机构、国际知名企业及企业家个人、一二级市场投资人组成。长城基金围绕香港长城投资商学院卓越的资源，积极参与并以资本助力来自长城投资商学院及高校的拥有创新、高成长型的初创型企业及创业者。`,
  GreatWallFundTxt2: `长城基金以粤港澳大湾区为核心，链接境内外投资资源，构建多元化企业赋能体系。拥有深厚的资源、远见以及经验，可以多种不同的方式帮助企业发展；在扩大圈层的同时覆盖更多优秀的创业者和项目作为项目储备。`,
  orientation1: `投资于新一代技术领域、国家扶持的新兴行业，聚焦拥有较高技术门槛和市场壁垒的企业。`,
  orientation2: `成长期、成熟期、上市、并购，企业发展全生命周期。`,
  orientation3: `由投资小组推进项目增值，资本小组以FA+投资帮助企业融资、战略小组提供投融培训、政府关系小组帮助企业平台推广（高交会）组成全方位专业服务，助力企业-资源-资本有效对接。`,
  orientation4: `香港长城投资商学院生态圈带来更多项目资源，促进产融结合、协同发展。`,
  orientation5: `以投资增长型企业为主，合理搭配投资组合，兼顾平衡投资回报及周期，力争风险最小化和收益最大化。`,
  education1: `学历教育+社会培训、国内+国际双轨教育并行，持续培养具有中华底蕴和国际视野的行业顶尖人才`,
  education2: `学历教育`,
  education3: `双语教学、国家承认学历，享受国际名校本科、研究生、博士生教育`,
  education4: `合作学院`,
  education5: `拉夫堡大学、瓦伦西亚大学、胡安卡洛斯国王大学、康普顿斯大学、巴塞罗那自治大学等`,
  social1: `社会培训`,
  social2: `打造公益课+精品课+定制课全方位 课程体系，提供职业资格培训、社会培训课程、承担社会责任，提升认知价值、凸显国家化社会培训独特优势。`,
  social3: `职业技能`,
  social4: `股权投资管理、家族财富办公室、数字资产管理、乡村振兴新农民教育等`,
  social5: `社会培训`,
  social6: `国际投融资、产业治理、区块链产业、财富管理、家庭财商等`,
  moreClass: `查看更多课程`,
  International1: '投',
  International2: '融',
  International3: '国',
  International4: '际',
  International5: '化',
  InternationalInfo1: '投融资国际化',
  InternationalInfo2: `跨境投资服务；企业跨境IPO服务；企业跨境股权、债券、
  项目合作等融资服务；企业跨境资产转让、变卖委托等。`,
  financing1: '科',
  financing2: '技',
  financing3: '国',
  financing4: '际',
  financing5: '化',
  financingInfo1: '科技国际化',
  financingInfo2: `科技项目服务;科技人才引进或输出服务;科技技术引进或输出服务。`,
  industry1: '产',
  industry2: '业',
  industry3: '国',
  industry4: '际',
  industry5: '化',
  industryInfo1: '产业国际化',
  industryInfo2: `产业治理与规划服务；产业国际扩张、项目落地服务；境外政府机构对接、商情调研服务； 国际产业人才引进服务、国际财税、法律服务。`,
  internationalInfo: '国际湾区经济峰会以全球最高规格的年度国际湾区经济发展峰会为定位，旨在搭建研究全球四大湾产业区产业发展、挖掘投资合作机会、促进全球经济发展的高品质交流平台，将在区域经济融合、城市发展、科技创新、产业升级、金融投资、人才培养、 资源汇聚和投资推广等多方面发挥积极作用。'
}

export const firstPageEN = {
  aboutWallTitle: 'About Great Wall Investment Business School',
  aboutWallTitleDes1: `Since its establishment, The Hong Kong Great Wall Investment Business School has always adhered to the educational philosophy of "education serves social development", using "international education" to convey the value of thinking; Adhering to the investment philosophy of "capital service industry development", "Great Wall Fund" is used to assist the growth of enterprises; Adhering to the industrial service concept of "internationalizing thinking and serving the international development of enterprises", we drive the international development of industries with "international service".`,
  aboutWallTitleDes2: `After ten years of prosperity, the Great Wall Investment Business School has held more than 1000 financing trainings, project roadshows, high-end summits, round table forums, and other activities in strategic emerging industries such as artificial intelligence, the Internet, new energy, new materials, aerospace, biopharmaceuticals, and big health in this vibrant land of Shenzhen.`,
  GreatWallFundTxt1: 'Great Wall Fund has a diversified portfolio of investors, consisting of government, global venture capital leading financial institutions, internationally renowned enterprises and individual entrepreneurs, and investors in primary and secondary markets. Centering on the outstanding resources of Great Wall Investment Business School, Great Wall Fund actively participates in and assists innovative, high-growth start-ups and entrepreneurs from Great Wall Investment Business School and universities with capital.',
  GreatWallFundTxt2: 'The Great Wall Fund takes the Greater Bay Area of Guangdong, Hong Kong, and Macao as its core, linking domestic and foreign investment resources, and constructing a diversified enterprise empowerment system. With profound resources, foresight, and experience, we can help the development of the enterprise in various ways; Expand the circle while covering more outstanding entrepreneurs and projects as project reserves.',
  orientation1: `Invest in new generation technology fields and emerging industries supported by the state, focusing on enterprises with high technology barriers and market barriers.`,
  orientation2: `Growth period, maturity period, listing, mergers and acquisitions, and the entire life cycle of enterprise development.`,
  orientation3: `Comprehensively professional services composed of investment team, strategic team, and government relations team to assist in the effective integration of enterprises, resources, and capital.`,
  orientation4: `The ecosystem of the Great Wall Investment Business School brings more project resources, promoting the integration of industry and finance, and coordinated development.`,
  orientation5: `Focusing on investment growth oriented enterprises, we should rationally allocate investment portfolios, balance investment returns and cycles, and strive to minimize risks and maximize returns.`,
  education1: `Academic education, social training, and domestic and international dual track education are in parallel, continuously cultivating industry top talents with Chinese heritage and international vision`,
  education2: `Academic Education`,
  education3: `Bilingual teaching, nationally recognized academic qualifications, and access to undergraduate, graduate, and doctoral education from internationally renowned universities`,
  education4: `Collaborating colleges`,
  education5: `University of Loughborough, University of Valencia, King Juan Carlos University, University of Compton, Autonomous University of Barcelona, etc`,
  social1: `Social Training`,
  social2: `Create a comprehensive curriculum system of public welfare courses, high-quality courses, and customized courses, provide vocational qualification training, social training courses, and assume social responsibility, enhance cognitive value, and highlight the unique advantages of national social training.`,
  social3: `Professional skills`,
  social4: `equity investment management, family wealth office, digital asset management, rural revitalization, new farmer education, etc`,
  social5: `Social training`,
  social6: `international investment and financing, industrial governance, blockchain industry, wealth management, family financial intelligence, etc`,
  moreClass: `More courses`,
  International1: 'International',
  International2: 'Investment',
  International3: 'And',
  International4: 'Financing',
  International5: 'Services',
  InternationalInfo1: 'International investment and financing services',
  InternationalInfo2: `Cross-border investment services; Corporate cross-border IPO services; Corporate cross-border equity, bonds, project cooperation and other financing services; Enterprises' cross-border asset transfer, sales commission, etc.`,
  financing1: 'International',
  financing2: 'Investment',
  financing3: 'And',
  financing4: 'Financing',
  financing5: 'Services',
  financingInfo1: 'International Investment And Financing Services',
  financingInfo2: `Technology project services; Technology talent introduction or export services; Technology import or export services.`,
  industry1: 'Industry',
  industry2: 'Internationalization',
  industry3: '',
  industry4: '',
  industry5: '',
  industryInfo1: 'Industry internationalization',
  industryInfo2: `Industrial governance and planning services; International industrial expansion and project landing services; Foreign government agencies docking, business research services; International industrial talent introduction services, international finance and taxation, legal services.`,
  internationalInfo: 'The International Bay Area Economic Summit is positioned as the world’s highest-level annual International Bay Area Economic Development Summit. It aims to build a high-quality exchange platform for studying the industrial development of the four major bay area industrial zones in the world, tapping investment cooperation opportunities, and promoting global economic development, economic integration and urban development. It plays an active role in many aspects, including city development, technological innovation, industrial upgrading, financial investment, talent training, resource gathering and investment promotion. '
}
