export const educationZH = {
  content1: '本周课程预告',
  content2: '本周热点资讯',
  courses1: '课程设置',
  courses2: '从学员和企业的实际发展需求出发，高标 准设置国内与国际两个市场通用的课程，促进个人发展和企业经营国际化',
  courses3: '教学特色',
  courses4: '双师教学，专业教师讲授理论知识，投资行业导师现场进行实践指导',
  courses5: '教学内容',
  courses6: '突出实用性、动态性、实操性，把理论与实践、项目与资本、需求与体验等内容融为一体，走独具特色的实战演练之路',
  courses7: '授课形式',
  courses8: '采取希腊封闭课堂与希伯来开放课堂相结合的方式',
  courses9: '教学方法',
  courses10: '融理论研究于实际案例中，把培训过程转变成商业模式的体验过程，达到融会贯通之目的',
  courses11: '学习效果',
  courses12: '通过实战性的学习与企业式培训，体现课程教学的价值所在',
  moreClass: '点击获取更多课程'
}

export const educationEN = {
  content1: 'Upcoming Classes',
  content2: 'Hotspot Information',
  courses1: 'Curriculum',
  courses2: 'Based on the actual development needs of students and enterprises, courses commonly used in both domestic and international markets are set up with high standards to promote personal development and internationalization of enterprise operations',
  courses3: 'Teaching Features',
  courses4: 'Dual teacher teaching, professional teachers teach theoretical knowledge, and investment industry mentors provide on-site practical guidance',
  courses5: 'Content Of Courses',
  courses6: 'Highlight practicality, dynamism, and practicality, integrate theory and practice, project and capital, demand and experience, and take a unique path of practical exercises.',
  courses7: 'Teaching Form',
  courses8: 'Adopt a combination of Greek closed classroom and Hebrew open classroom',
  courses9: 'Teaching Method',
  courses10: 'Integrate theoretical research into practical cases to transform the training process into a business model experience process, achieving the goal of integration',
  courses11: 'learning Effect',
  courses12: 'Reflect the value of curriculum teaching through practical learning and enterprise training',
  moreClass: 'Click for more courses'
}
