import {firstPageEN} from './modules/firstPage'
import {aboutUsEN} from './modules/aboutUs'
import {educationEN} from './modules/education'
import {inverstmentEN} from './modules/inverstment'
import {certificateEN} from './modules/certificate'
export const main={
    firstPageContent: firstPageEN,
    aboutUsContent: aboutUsEN,
    educationContent: educationEN,
    inverstmentContent: inverstmentEN,
    certificateContent: certificateEN,
    HomePage:'Home',
    AboutUs:'About',//关于我们
    GreatWallEducation:'Education',//长城教育
    GreatWallInvestment:'Investment', //长城投资
    InternationalSummit:'Summit',//国际峰会
    NewsCenter:'News',//新闻中心
    CertificateQuery:'Certificate' ,// 证书查询

    AboutGreatWall:'About Great Wall',
    GreatWallFund:'Great Wall Fund',
    DualTrackEducation:'Dual Track Education',
    QualityCourseRecommendation:'Quality Course Recommendation',
    InternationalInvestmentAndFinancingServices:'International Investment And Financing Services',
    CooperativePartner:'Cooperative Partner',

    BrandStory:'Brand Story',
    Subsidiaries:'Subsidiaries',
    CollegeLeaders:'College Leaders',
    MentorTeam:'Mentor Team',
    ContactUs:'Contact Us',

    ThreeMajorCourses:'Major Courses',
    CourseFeatures:'Course Features',

    OurAdvantages:'Our Advantages',
    SuccessCases:'Success Cases',
    InvestmentAndFinancingProcess:'Investment And Financing Process',
    InvestmentAndFinancingServices:'Investment And Financing Services',

    SummitIntroduction:'Summit Introduction',
    SponsorIntroduction:'Sponsor Introduction',
    GuestsPresent:'Guests Present',
    AttendingInstitutions:'Attending Institutions',

    HotNews:'Hot News',
    UpcomingClasses:'Upcoming Classes',

    footerAddress: '1406, New World Cultural Expo Center, 2005 Lianhua Road, Futian District, Shenzhen',
    footerTelePhone: 'telephone',
    footerCopyright: 'Copyright Great Wall Investment Business School',
    footerOffice: 'Official Accounts',
    footerHelper: 'Assistant',
    detailInfo: 'Detail',
    noData: 'NO DATA'
}
