<template>
  <footer class="footer-box">
    <div class="footer-content-box auto-width">
      <div class="left-box">
        <img class="ligu-logo" src="../../assets/img/footer_logo.png"/>
        
      </div>
      <div class="right-box">
         <div class="r-txt">{{$t('main.footerAddress')}} &nbsp;&nbsp;{{$t('main.footerTelePhone')}}：0755-82774424</div>
         <div class="r-txt m-top">{{$t('main.footerCopyright')}}&nbsp;&nbsp; ｜&nbsp;&nbsp;Copyright&nbsp;2002&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备18082446号-3</div>
      </div>
      <div class="r-box">
        <div class="wx-img">{{$t('main.footerOffice')}}</div>
        <div class="wx-img">{{$t('main.footerHelper')}}</div>
      </div>
    </div>
     
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        timeEnd: ''
      }
    },
    created() {
      this.timeEnd = new Date().getFullYear()
    }
  }
</script>

<style scoped lang="scss">
  .footer-box{  
    
    height: 134px;
    
    z-index: 1;
    background: #444546;
    .footer-content-box{
      padding: 0 300px;
      display: flex;
      .left-box{
        flex-basis: 90px;
      }
      .ligu-logo{
        width: 73px;
        height: 88px;
        margin-top: 22px;
      }
      .right-box{
        flex: 1;
        height: 64px;
        margin-top: 35px;
        color: #fff;
        border-left: 1px solid #fff;
        padding-left: 16px;
        font-size: 14px;
        .m-top{
          margin-top: 28px;
        }
      }
      .r-box{
        flex-basis: 176px;
        font-size: 14px;color: #fff;
        margin-top: 16px;
        height: 102px;
        .wx-img{
          padding-top: 80px;
          background: url(../../assets/img/wx_img.png) center top no-repeat;
          background-size: 72px;
          width: 72px;
          text-align: center;
          margin-left: 16px;
          float: left;
        }
      }

    }
  }
</style>
