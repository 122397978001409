export const certificateZH = {
  name: '姓名',
  nameQuery: '请输入姓名',
  idName: '身份证号码',
  idNameQuery: '请输入身份证号码',
  query: '查询',
  order: '序号',
  oorderNumber: '证书编号',
  userName: '学员姓名',
  certificateName: '对应课程(证书名称)',
  issuanceTime: '发证日期',
  content1: '无证书信息，如有疑问请联络客服',
  content2: '拨打电话0755-82774424反馈'
}

export const certificateEN = {
  name: 'Name',
  nameQuery: 'Please Enter Your Name',
  idName: 'ID Number',
  idNameQuery: 'Please Enter Your  ID Number',
  query: 'Inquire About',
  order: '序号',
  oorderNumber: '证书编号',
  userName: '学员姓名',
  certificateName: '对应课程(证书名称)',
  issuanceTime: '发证日期',
  content1: 'No certificate information, please contact customer service if you have any questions',
  content2: 'Or call 0755-82774424 for feedback'
}
