export const aboutUsZH = {
  content1: `香港长城投资商学院成立以来，始终秉承“教育服务社会发展”的办学理念，用“国际教育”传递思维价值；秉承“资本服务产业发展”的投资理念，以“长城基金”助力企业成长；秉承“国际化思维服务企业国际化发展”的产业服务理念，以“国际服务”带动产业国际化发展。`,
  content2: `2019年香港长城投资商学院落地深圳，下设深圳市维佳力教育科技有限责任公司、深圳市亚欧文化教育有限公司、 深圳市深商创富投资管理有限公司分别负责教育、基金及企业服务事务。`,
  more: '了解更多',
  schoolName: '香港长城投资商学院',
  address: '地址：深圳市福田区新世界文博中心1406号',
  phone: '电话：0755-82774424',
  email: '邮箱：fay307@163.com'
}

export const aboutUsEN = {
  content1: `Since its establishment, The Hong Kong Great Wall Investment Business School has always adhered to the educational philosophy of "education serves social development", using "international education" to convey the value of thinking; Adhering to the investment philosophy of "capital service industry development", "Great Wall Fund" is used to assist the growth of enterprises; Adhering to the industrial service concept of "internationalizing thinking and serving the international development of enterprises", we drive the international development of industries with "international service".`,
  content2: `In 2019, Hong Kong Great Wall Investment and Business School acquired Shenzhen Weijiali Education Technology Co., Ltd., Shenzhen Asia Europe Culture and Education Co., Ltd. and Shenzhen Shenshang Chuangfu Investment Management Co., Ltd. were respectively responsible for education, fund and enterprise services.`,
  more: 'More',
  schoolName: 'Great Wall Investment Business School',
  address: 'Add: No. 1406, New World Wenbo Center, Futian District, Shenzhen City',
  phone: 'Tel: 0755-82774424',
  email: 'Email: fay307@163.com'
}
