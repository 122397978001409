<template>
  <header class="header-box" :class="styleClassDefault">
     <div class="header-content-box auto-width">
       <div class="left-box">

          <div class="ligu-logo" @click="navClick('Index')"></div>

         <nav class="nav-box">
           <!-- <li class="item-box" v-for="(item, index) in navList" :key="index">
             <div class="item-text" @click="navClick(item.path)" :class="item.path === navProperty ? 'active' : ''">
               {{item.label}}
             </div>
           </li> -->
           <li class="item-box">
             <div class="item-text" @click="navClick('aboutUs')" :class="'aboutUs' === navProperty ? 'active' : ''">
               {{$t('main.AboutUs')}}
             </div>
           </li>
           <li class="item-box">
             <div class="item-text" @click="navClick('ccEducation')" :class="'ccEducation' === navProperty ? 'active' : ''">
               {{$t('main.GreatWallEducation')}}
             </div>
            </li>
           <li class="item-box">
             <div class="item-text" @click="navClick('ccInvestment')" :class="'ccInvestment' === navProperty ? 'active' : ''">
               {{$t('main.GreatWallInvestment')}}
             </div>
            </li>
           <li class="item-box">
             <div class="item-text" @click="navClick('internationalSummit')" :class="'internationalSummit' === navProperty ? 'active' : ''">
               {{$t('main.InternationalSummit')}}
             </div>
            </li>
           <li class="item-box">
             <div class="item-text" @click="navClick('newsCenter')" :class="'newsCenter' === navProperty ? 'active' : ''">
               {{$t('main.NewsCenter')}}
             </div>
            </li>
           <li class="item-box">
             <div class="item-text" @click="navClick('certificateQuery')" :class="'certificateQuery' === navProperty ? 'active' : ''">
               {{$t('main.CertificateQuery')}}
             </div>
           </li>
           <li class="item-box" style="width:60px">
             <span class="langtxt" @click="changeLanguage('zh-CN')" :class="curLang == 'zh-CN' ? 'active' : ''">
              CN/
             </span>
             <span class="langtxt" @click="changeLanguage('en-US')" :class="curLang == 'en-US' ? 'active' : ''">
              EN
             </span>
           </li>
         </nav>
       </div>

     </div>

     <div class="fixed-zx">
      <div class="zx-warp">
        <div class="line"></div>
        <div class="zxzx-list">
          <div class="zx-item zx1" @click="showwx=true" @mouseenter="showwx=!showwx" @mouseleave="showwx=false">
            在<br />
            线<br />
            咨<br />
            询
          </div>
          <div class="h-line"></div>
          <div class="zx-item zx2" @click="navClick('Index')">
            回<br />
            到<br />
            首<br />
            页
          </div>
        </div>
        <div class="wx-fixed" v-if="showwx">
          <img src="../../assets/img/wx_big.png" class="wx-img" />
        </div>
      </div>
     </div>
  </header>
</template>

<script>
  export default {
    props: {
      // navProperty: {
      //   type: String,
      //   default: 'index'
      // },
      styleClassDefault: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        navProperty: '',
        showwx:false,
        curLang:'zh-CN', //en-US
        navIndex: 0,
        navList: [
          {
            label:  '关于我们',
            path: 'aboutUs'
          },
          {
            label: '长城教育',
            path: 'ccEducation'
          },
          {
            label: '长城投资',
            path: 'ccInvestment'
          },
          {
            label: '国际峰会',
            path: 'internationalSummit'
          },
          {
            label: '新闻中心',
            path: 'newsCenter'
          },
          {
            label: '证书查询',
            path: 'certificateQuery'
          }
        ]
      }
    },
    created(){
      // let _cur = window.sessionStorage.getItem('languageType');
      // this.curLang = _cur;
      // this.$i18n.locale=window.sessionStorage.getItem('languageType');
      this.$i18n.locale = 'zh-CN'
    },
    methods: {
      gotoMain(){
        this.$router.push('/')
      },
      navClick(path) {
        const currentPath = this.$route.name;
        if (path === currentPath) {
          return
        }
        this.navProperty = path
        this.$router.push({name: path})
      },
      changeLanguage(val){
        //console.log(this.$t('main.aboutUs'))
        this.$i18n.locale=val;
        this.curLang = val;
        window.sessionStorage.setItem('languageType',val);
      },

    }
  }
</script>

<style scoped lang="scss">

.header-box{
  .fixed-zx{
    position: fixed;
    right: 0;
    top: 376px;
    z-index: 999;
    width: 250px;
    height: 288px;
    .zx-warp{
      position: relative;
      .line{
        position: absolute;
        width: 53px; height: 288px;
        top: 5px; right: 5px;
        z-index: 1;
        border: 1px solid #4263B6;
      }
      .h-line{
        width: 29px; border-top: 1px solid #E3EDFC;

        margin: 17px auto 17px;
      }
      .zx-item{
        padding-top: 44px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        &.zx1{
          background: url(../../assets/img/kf_icon.png) center 17px no-repeat;
          background-size: 25px;
        }
        &.zx2{
          background: url(../../assets/img/footer_logo.png) center 0 no-repeat;
          background-size: 22px 28px;
        }
      }
      .zxzx-list{
        position: absolute;
        top: 0; right: 0;
        background: #4263B6;
        width: 53px; height: 288px;
        z-index: 111;
      }
      .wx-img{
        width: 180px; height: 180px;
      }

    }
  }
  width: 100%;
  height: 80px;
  background: rgba(0,0,0,0.40);
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 99999;
  top: 0;
  z-index: 1000;

  .langtxt{
        display: inline-block;
        cursor: pointer;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.72);
        line-height: 80px;

        &.active{
          color: #fff;
        }

        &:hover{
          color: rgba(255,255,255,0.6);
        }
        &.active:hover{
          color: rgba(255,255,255,1);
        }
      }

  &.white{
    background: #fff;
    .item-text, .tell-text{

      color: #2B2D30 !important;
    }
    .item-text{
      font-weight: 600 !important;
    }


    .item-text:hover{
      color: rgba(43,45,48,0.6) !important;
    }
    .item-text.active:hover{
      color: rgba(43,45,48,1) !important;
    }
    .ligu-logo{
      cursor: pointer;
      float: left;
        margin-top: 16px;
      background: url(../../assets/img/logo.png) center no-repeat !important;
      background-size: contain !important;
    }
  }
  .header-content-box{
    height: 100%;

    align-items: center;
    .left-box{
      padding: 0 180px;
      position: relative;
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .ligu-logo{
        cursor: pointer;
        width: 238px;
        height: 48px;
        float: left;
        margin-top: 16px;
        background: url(../../assets/img/logo.png) center no-repeat;
        background-size: contain;
      }
      .nav-box {
        height: 100%;
        display: flex;
        // position: absolute;
        // top: 0;right: 0;
        // margin-left: 300px;
        .item-box{
          flex-basis: 90px; width: 90px;
          height: 100%;
          text-align: center;
          padding: 0 20px;
          .item-text{
            cursor: pointer;
            height: 100%;
            font-size: 16px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.72);
            line-height:18px;
            display: flex;
            align-items: center;
            position: relative;
            &.active{
              color: #fff;
            }
            &.active::after {
              content: " ";
              position: absolute;
              bottom: 22px;
              left: 23px;
              display: block;
              width: 20px;
              height: 2px;
              background: #fff;
            }
            &:hover{
              color: rgba(255,255,255,0.6);
            }
            &.active:hover{
              color: rgba(255,255,255,1);
            }
          }
        }
      }
    }
    .right-box{
      display: flex;
      .tell-box{
        display: flex;
        align-items: center;
        .tell-icon{
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
        .tell-text{
          font-size: 16px;
          font-family: D-DINExp, D;
          font-weight: normal;
          color: #FFFFFF;
        }
      }
      .go-look-box{
        height: 40px;
        border-radius: 2px;
        border: 1px solid #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
        margin-right: 7px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        &:hover{
          background: #FFFFFF;
          border-radius: 2px;
          color: #DA3041;
        }
      }
      .login-box{
        cursor: pointer;
        height: 40px;
        background: #DA3041;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 0 12px;
        display: flex;
        align-items: center;
        a{
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

}
</style>
