export const inverstmentZH = {
  content1: '你是否还在焦虑,存在以下痛点',
  content2: '不懂融资方法，也没有人脉关系，跑了不少冤枉路就是融不到资金？',
  content3: '公司想上市却被拦在IPO门外？',
  content4: '手上有资金，但一直找不到合适的项目或项目估值过高不便入场？',
  content5: '别担心，香港长城投资商学院一站式帮你解决所有投融资难题！',
  content6: '你是否还在焦虑,存在以下痛点',
  content7: '你是否还在焦虑,存在以下痛点',
  content8: '你是否还在焦虑,存在以下痛点',
  content9: '你是否还在焦虑,存在以下痛点',
  introInfo1: '强大的投资网络',
  introInfo2: '设长城基金，积极参与并以资本助力优质企业及优秀创业者；多元化投资网络，能够为项目快速、精准地匹配到合适的投资机构',
  introInfo3: '快速的项目获取',
  introInfo4: '专业分析行业信息、市场规模、竞争格局、产业链、商业模式等，快速摸清项目亮点，发现优质项目',
  introInfo5: '专业的增值服务',
  introInfo6: '全方位专业服务，促进企业-资源-资本有效对接',
  companyInfo1: '中琛源科技',
  companyInfo2: '立咕运动',
  companyInfo3: '卫邦科技',
  companyInfo4: '创链数据',
  inverstInfo1: '投融资合作也可以很简单',
  inverstStepInfo1: '免费注册',
  inverstStepInfo2: '实名认证，保障信息安全',
  inverstStepInfo3: '发布/寻找项目',
  inverstStepInfo4: '高效匹配',
  inverstStepInfo5: '精准对接企业所属赛道、所属阶段的专业投资机构',
  inverstStepInfo6: '线下约谈',
  inverstStepInfo7: '线上直接对接+线下活动面谈，降低成本提升效率',
  inverstStepInfo8: '达成协议',
  inverstStepInfo9: '双方签署意向协议，成功合作',
  serviseInfo1: '还有众多增值服务等着你',
  serviseInfo2: '商业计划书撰写指导',
  serviseInfo3: '商业模式优化',
  serviseInfo4: '股权顶层架构设计',
  serviseInfo5: '商业考察',
  serviseInfo6: '参加大型国际金融类会议',
  serviseInfo7: '点击开启专属服务'
}

export const inverstmentEN = {
  content1: '你是否还在焦虑,存在以下痛点',
  content2: '不懂融资方法，也没有人脉关系，跑了不少冤枉路就是融不到资金？',
  content3: '公司想上市却被拦在IPO门外？',
  content4: '手上有资金，但一直找不到合适的项目或项目估值过高不便入场？',
  content5: '别担心，香港长城投资商学院一站式帮你解决所有投融资难题！',
  content6: '你是否还在焦虑,存在以下痛点',
  content7: '你是否还在焦虑,存在以下痛点',
  content8: '你是否还在焦虑,存在以下痛点',
  content9: '你是否还在焦虑,存在以下痛点',
  introInfo1: '强大的投资网络',
  introInfo2: '设长城基金，积极参与并以资本助力优质企业及优秀创业者；多元化投资网络，能够为项目快速、精准地匹配到合适的投资机构',
  introInfo3: '快速的项目获取',
  introInfo4: '专业分析行业信息、市场规模、竞争格局、产业链、商业模式等，快速摸清项目亮点，发现优质项目',
  introInfo5: '专业的增值服务',
  introInfo6: '全方位专业服务，促进企业-资源-资本有效对接',
  companyInfo1: '中琛源科技',
  companyInfo2: '立咕运动',
  companyInfo3: '卫邦科技',
  companyInfo4: '创链数据',
  inverstInfo1: '投融资合作也可以很简单',
  inverstStepInfo1: '免费注册',
  inverstStepInfo2: '实名认证，保障信息安全',
  inverstStepInfo3: '发布/寻找项目',
  inverstStepInfo4: '高效匹配',
  inverstStepInfo5: '精准对接企业所属赛道、所属阶段的专业投资机构',
  inverstStepInfo6: '线下约谈',
  inverstStepInfo7: '线上直接对接+线下活动面谈，降低成本提升效率',
  inverstStepInfo8: '达成协议',
  inverstStepInfo9: '双方签署意向协议，成功合作',
  serviseInfo1: '还有众多增值服务等着你',
  serviseInfo2: '商业计划书撰写指导',
  serviseInfo3: '商业模式优化',
  serviseInfo4: '股权顶层架构设计',
  serviseInfo5: '商业考察',
  serviseInfo6: '参加大型国际金融类会议',
  serviseInfo7: '点击开启专属服务'
}
