// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from'element-ui';
import'element-ui/lib/theme-chalk/index.css';
import '@/assets/font/font.css';
import '@/assets/style/main.scss';
import VueAMap from 'vue-amap';

import { allMixin } from './mixins'
import api from '@/plugins/fetch/index.js'

import vueI18n from 'vue-i18n'
Vue.use(vueI18n)

//图片请求的前缀
Vue.prototype.$api = api


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.mixin(allMixin)
const locale=localStorage.getItem('languageType')
const i18n=new vueI18n({
	locale:locale||'zh-CN',
  fallbackLocale: 'zh',  // 默认语言为中文
	messages:{
		'zh-CN':require('@/assets/lang/zh'),
		'en-US':require('@/assets/lang/en'),
	}
})

// 引入高德地图
Vue.use(VueAMap);

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '49d04e5cf43c010349306486190dc5d3',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  render:h=>h(App)
})
